<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <div>
    <v-tabs id="iiotRolePermissionsTabs" ref="rolePermissions" v-model="tabs" background-color="grey lighten-4">
      <v-tab id="iiotRolePermissionsUiTab">
        {{ $t('access.roles.roleDetails.uiPermissionsTab') }}
      </v-tab>
      <v-tab id="iiotRolePermissionsApiTab">
        {{ $t('access.roles.roleDetails.apiPermissionsTab') }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabs">
      <v-tab-item>
        <ui-permissions class="permissions" />
      </v-tab-item>
      <v-tab-item>
        <api-permissions class="permissions" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import UiPermissions from '@/components/roles/UiPermissions.vue';
import ApiPermissions from '@/components/roles/ApiPermissions.vue';
import Logger from '@/utils/logger';

export default {
  components: {
    UiPermissions,
    ApiPermissions,
  },
  data: () => ({
    tabs: null,
  }),
  computed: {},
  async mounted() {
    try {
      await this.$store.dispatch('roles/fetch_permissions', {
        searchText: '',
        categories: '',
      });
    } catch (e) {
      Logger.error(e);
    }
  },
};
</script>
<style lang="scss">
.permissions {
  .default-table > .v-data-table__wrapper {
    overflow-y: auto;
    overflow-x: auto;
    max-height: 50vh;
  }
}
</style>
